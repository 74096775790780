<template>
  <div class="w-100">
    <strong>Escopo do serviço</strong>
    <hr />

    <h5 class="d-flex align-items-center justify-content-between">
      {{ service_scope.scope }}
    </h5>
    <div class="service-scroll">
      <div class="my-3">
        <strong class="d-block mb-2">Serviços</strong>
        <div class="d-flex flex-wrap">
          <span v-for="(service, index) in service_scope.services" :key="index">
            {{ service.activity }}
          </span>
        </div>
        <hr />
      </div>
      <div class="my-3">
        <strong>Perído do serviço</strong>
        <span> ({{ service_scope.contract_months }} meses)</span>
        <div class="mt-2 text-muted  ">
          <div>
            <b-icon icon="calendar3" />
            <span class="ml-2">{{ service_scope.date_init | dateFull }}</span>
          </div>
          <div>
            <b-icon icon="calendar4" />
            <span class="ml-2"> {{ service_scope.date_end | dateFull }}</span>
          </div>
        </div>
        <hr />
      </div>
      <div class="my-3">
        <strong>Valor deste contrato</strong>
        <div class="mt-2 text-muted">
          <div class="d-flex align-items-center">
            <b-icon icon="cash" />
            <span class="mx-2">
              Mensal:
              {{ service_scope.contract_value_mensal | money }}
            </span>
          </div>
          <div class="d-flex align-items-center mt-1">
            <b-icon icon="cash" />
            <span class="mx-2 d-block">
              Total:
              {{ service_scope.contract_value_total | money }}
            </span>
          </div>
        </div>
        <hr />
      </div>
      <div class="my-3">
        <strong>Faturamento mensal exigido do prestador</strong>
        <div class="mt-2 text-muted d-flex align-items-center">
          <b-icon icon="cash" />
          <span class="mx-2 d-block">
            {{ service_scope.monthly_invoicing_above | money }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      service_scope: {},
    };
  },

  methods: {
    ...mapActions(["get_service_scope"]),
    getServiceScope() {
      this.get_service_scope({ id: this.service_id }).then((response) => {
        this.service_scope = response;
      });
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.getServiceScope();
  },
};
</script>

<style lang="scss">
.service-scroll {
  overflow: auto;
  height: 55vh;
}
</style>
